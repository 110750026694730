:root {
  --main-background-color: #02121d;
  --formic-profile-picture: #172e4e;
  --formic-icon-background-color: #e0a966;
  --formic-button-color: #0b3c60;
  --main-text-color: white;
  --form-text-color: #0b3c60;
  --button-main-text-color: white;
  --button-main-bg-color: #0b3c60;
  --button-main-hover-color: #93670f;
  --formic-primary-background: #d5d6d8;
  --formic-text-color: #0b3c60;
  --button-light: #446075;
  --secondary-bg-color: #0b3c60;
  --success-message: #4caf50;
  --error-message: #7e1313;
  --input-box-shadow: #dcc79e;
  --unstaged-message: #c78144;
  --user-preferences-bg-color: #efefef;
  --home-hover-color: #e5e4e2;
  --info-icon: #0099ff;
  --standard-border-radius: 8px;
}

body {
  background-color: var(--main-background-color);
  color: var(--form-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  height: 100%;
}

input {
  border-radius: 8px;
}

.form-control {
  background-color: #02121d;
  color: white;
}

.form-control::placeholder {
  color: gray;
}

.form-control:focus {
  border-color: var(--button-main-hover-color);
  box-shadow: 0 0 0 0.1rem var(--input-box-shadow);
  background-color: #02121d;
  color: white;
}

.btn-color-main {
  color: var(--button-main-text-color);
  border-style:solid;
  width: auto;
  border-color: #0b3c60;
  box-shadow: 0 4px 8px 0 rgba(0, 234, 255, 0.3);
}

.btn-color-main:hover {
  border-color: #e0a966;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 #e0a966;
}

.btn-color-main:focus {
  border-color: #e0a966;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 #e0a966;
}

.btn-color-main:disabled {
  border-color: gray;
  color: gray;
}

.btn-color-light {
  background-color: var(--button-light);
  font-weight: bold;
  color: var(--main-text-color);
  transition: all 0.3s ease;
  width: auto;
}

.btn-color-light:hover {
  background-color: var(--button-main-bg-color);
  color: var(--button-main-text-color);
}

.btn-color-secondary {
  color: var(--button-main-text-color);
  transition: all 0.3s ease;
  width: auto;
  border-color: #e0a966;
  transition: all 0.3s ease;
  border-style:solid;
  box-shadow: 0 4px 8px 0 #e0a966;
}

.btn-color-secondary:hover {
  border-color: #0b3c60;
  transition: all 0.3s ease;
  border-style:solid;
  box-shadow: 0 4px 8px 0 rgba(0, 234, 255, 0.3);
  color: var(--button-main-text-color);
}

.btn-color-secondary:disabled {
  border-color: gray;
  color: gray;
}

.secondary {
  box-shadow: 8px 8px 15px 0px rgb(124, 115, 115);
  border: solid;
  border-color: white;
  padding: 10px 10px;
  border-radius: 8px;
}

.required {
  color: #e0a966;
  margin-bottom: 2px;
  font-weight: bold;
}

.success-message {
  background-color: var(--success-message);
  color: var(--main-text-color);
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.fail-message {
  background-color: var(--error-message);
  color: var(--main-text-color);
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}