.border {
  color: var(--main-text-color);
}

.footer {
  position: relative;
  width: 100%;
  border-top: 1px solid var(--main-text-color);
  padding: 10px;
  bottom: 0;
}

.footer-company {
  color: var(--main-text-color);
  font-weight: bold;
  text-decoration: none;
}

.brand {
  text-align: center;
  text-decoration: none;
  color: var(--main-text-color);
  transition: all 0.3s ease;
}

.footer-icon {
  font-size: 40px;
  color: var(--main-text-color);
  transition: all 0.3s ease;
}

.footer-icon:hover {
  font-size: 40px;
  color: var(--formic-icon-background-color);
}

.rights {
  margin-top: 3em;
}

.rights-text {
  font-size: 15px;
}

.footer-main {
  margin-bottom: 15px;
}

.brand:hover {
  color: var(--formic-icon-background-color);
}
