.toggle-style {
  display: flex;
  align-items: center;
}

a.dropdown-item:hover {
  color: var(--formic-icon-background-color);
  background-color: var(--main-background-color);
}

.dropdown-divider {
  background-color: white;
}

a.dropdown-item {
  color: white;
  font-weight: bold;
}

.login-btn {
  border: 2px solid var(--button-main-bg-color);
  font-weight: bold;
  background-color: var(--button-main-text-color);
  color: var(--button-main-bg-color);
  transition: all 0.3s ease;
  width: auto;
  margin-right: 30px;
  border-radius: var(--standard-border-radius);
  width: 120px;
}

.login-btn:hover {
  background-color: var(--button-main-bg-color);
  color: var(--button-main-text-color);
  border: 2px solid var(--button-main-bg-color);
}
