.inactive-container {
  width: 500px;
  justify-content: center;
  margin-top: 30px;
}

.inactive-box {
  color:white;
  border: solid;
  border-color: white;
  border-radius: 8px;
  box-shadow: 8px 8px 15px 0px rgb(124, 115, 115);
}

.advertise {
  margin-top: 10px;
  justify-content: center;
  text-align: center;
}

.margin-inactive {
  margin-top: 5em;
}
