.MuiTab-root.tab-btn {
  color: rgba(255, 255, 255, 0.7);
}

.MuiTab-root.tab-btn.Mui-selected {
  color: white;
  font-weight: bolder;
}

.MuiTab-root.tab-btn.Mui-disabled {
  color: grey;
}

.tab-panel {
  flex-grow: 1;
}
