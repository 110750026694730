.register-container {
  width: 540px;
  justify-content: center;
}

.container-ratio {
  display: flex;
  justify-content: center;
  align-items: center;
}

html, body, #root {
  width: 100%;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 720px) {
  html {
    width: 720px;
    overflow: auto;
  }
}

/* @media (max-width: 1170px) {
  body {
    max-width: 1170px;
    margin: 0 auto;
  }
} */

@media (max-width: 992px) {
  .container-ratio {
    width: 90%;
  }
  .register-container {
    width: 100%;
  }
  .login-container {
    width: auto;
  }
  .error-messages-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .container-ratio {
    flex-direction: column;
  }
}

.error-messages-container {
  width: 700px;
  justify-content: center;
  align-items: center;
}

.box-style {
  border-radius: 8px;
}

.main-comp {
  border-radius: 8px;
  padding: 20px 20px;
}

.center-error {
  text-align: center !important;
  align-items: center;
  justify-content: center !important;
}

.bold-text {
  font-weight: bold;
}

.width-setter {
  margin-bottom: 6px;
}

.register-password {
  margin-top: 8px;
}

.profile-password-label {
  display: flex;
  height: 100%;
}

.forgot-pass {
  text-decoration: underline;
  color: var(--formic-text-color);
  text-align: center;
}
