.memo-steps {
  margin-top: 2em;
}

.memo-container {
  padding-left: 10em;
  padding-right: 10em;
}

.organize-video-row {
  justify-content: center;
}

@media (min-width: 1600px) {
  .memo-container {
    padding-left: 25em;
    padding-right: 25em;
  }
}

@media (min-width: 2560px) {
  .overview-container {
    padding-left: 45em;
    padding-right: 45em;
  }
  .install-container {
    padding-left: 20vw;
    padding-right: 20vw;
    justify-content: center;
  }

  .memo-container {
    padding-left: 50em;
    padding-right: 50em;
  }

  .organize-video-row {
    display: flex;
    justify-content: center;
  }

  .memo-steps {
    margin-top: 2em;
  }
}

.memo-features {
  display: grid;
  justify-content: center;
}
