.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1em;
}

.title-heading {
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: white;
}

.light-logo {
  height: 12em;
  width: 12em;
  margin-right: 2em;
}

.greet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.greet .col-2 {
  flex-shrink: 0;
}

.greet .col-10 {
  flex-shrink: 0;
}

.greetings-container {
  width: 110%;
}

.session {
  color: green;
  font-weight: bold;
}
