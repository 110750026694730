:root {
  --document-highlight-color: #e0a966;
  --clicks-hover-color: #03e2f7;
  --document-border-color: #0b3c60;
}

.overview-heading {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
  color: var(--document-highlight-color);
  text-align: center;
}

.overview-container {
  padding-left: 10em;
  padding-right: 10em;
}

.next-button {
  display: flex;
  justify-content: right;
}

.clicks {
  text-decoration: underline;
  color: var(--document-highlight-color);
}

.clicks:hover {
  text-decoration: underline;
  color: var(--clicks-hover-color);
}

.next-btn {
  min-width: 15em;
  color: white;
  border-color: var(--document-border-color);
  transition: all 0.3s ease;
  border-style: solid;
  box-shadow: 0 4px 8px 0 rgba(0, 234, 255, 0.3);
  height: 2.5em;
  padding: 0 1em;
  white-space: nowrap;
}

.next-btn:hover {
  color: white;
  border-color: #e0a966;
  transition: all 0.3s ease;
  border-style: solid;
  box-shadow: 0 4px 8px 0 var(--document-highlight-color);
}

.faq-heading {
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
  color: var(--document-highlight-color);
}

.overview-feature-buttons {
  height: 14em;
  width: 100%;
  max-width: 19em;
  display: flex;
  padding: 1em;
  background-color: inherit;
  font-size: small;
  color: white;
  align-items: center;
  text-align: left;
  justify-content: center;
  border-radius: 0.5em;
  text-decoration: none;
  border-color: var(--document-border-color);
  transition: all 0.3s ease;
  border-style: solid;
  box-shadow: 0 4px 8px 0 #00eaff4d;
}

.overview-feature-buttons:hover {
  border-color: var(--document-highlight-color);
  transition: all 0.3s ease;
  border-style: solid;
  box-shadow: 0 4px 8px 0 var(--document-highlight-color);
}

.main-button-container {
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1300px;
}

.main-button-container:hover {
  text-decoration: none;
}

.browser-icon {
  height: 2em;
  width: 2em;
}

.video-play {
  width: 100%;
  max-width: 50em;
  height: 25em;
}

.general-links {
  color: var(--document-highlight-color);
  text-decoration: underline;
}

.general-links:hover {
  color: #0099ff;
}

.video-bar {
  display: grid;
  margin-bottom: 2em;
  justify-content: center;
}

.install-container {
  padding-left: 10em;
  padding-right: 10em;
  justify-content: center;
}

@media (min-width: 1500px) {
  .overview-container {
    padding-left: 22em;
    padding-right: 22em;
  }
  .install-container {
    padding-left: 15em;
    padding-right: 15em;
    justify-content: center;
  }
}

@media (min-width: 2560px) {
  .overview-container {
    padding-left: 45em;
    padding-right: 45em;
  }
  .install-container {
    padding-left: 20vw;
    padding-right: 20vw;
    justify-content: center;
  }
}

.install-text-container {
  padding-left: 5em;
  padding-right: 5em;
}

.overview-sub-heading {
  font-weight: bold;
}

.feature-button-group {
  display: flex;
  justify-content: center;
}

.overview-text {
  padding-left: 4em;
  padding-right: 4em;
}

p {
  padding: 0;
  margin-bottom: 0;
}

.documentation-para {
  margin-bottom: 1em;
}

.feature-heading {
  font-weight: bold;
  max-height: 3em;
  height: 100%;
}

.feature-text {
  color: rgb(167, 163, 163);
}

.feature-group {
  display: grid;
  align-items: center;
}

.documentation-navigator {
  display: flex;
  align-items: center;
  height: 5em;
  justify-content: space-between;
  border-top: double;
  border-bottom: double;
  border-color: var(--document-border-color);
  margin-top: 2em;
}
