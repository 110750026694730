.provider {
  color: var(--main-text-color);
  font-size: 35px;
  border-radius: 8px;
}

.provider-button {
  background-color: inherit;
  cursor: pointer;
  padding: 10px 20px;
  justify-content: center;
  border-radius: 8px;
  font-size: 20px;
  width: auto;
  height: auto;
  transition: all 0.3s ease;
  border-color: #0b3c60;
  transition: all 0.3s ease;
  border-style:solid;
  box-shadow: 0 4px 8px 0 rgba(0, 234, 255, 0.3);
}

.provider-button:hover {
  border-color: #e0a966;
  transition: all 0.3s ease;
  border-style:solid;
  box-shadow: 0 4px 8px 0 #e0a966;
}

.sso-icons-microsoft {
  justify-content: center;
  text-align: left;
}

.sso-icons-google {
  justify-content: center;
  text-align: right;
}
