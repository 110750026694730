.loading-spinner {
  color: var(--main-text-color);
}

.loading-overlay {
  /* Drop overlay on top of item  */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* Increase loading indicator contrast compared to background */
  background-color: gray;
  opacity: 0.7;

  /* Put loading indicator in the center */
  display: flex;
  justify-content: center;
  align-items: center;
}