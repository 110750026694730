.set-show-checkbox {
  padding: 0%;
  margin-top: 5px;
  margin-left: 12px;
}

.set-show-password {
  padding: 0%;
  margin-top: 5px;
}
