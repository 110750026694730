.video-title {
  color: white;
  width: 100%;
}

.video-container {
  padding: 3em;
  color: white;
  justify-content: left;
  text-align: left;
  width: 100%;
}

.topic-btn {
  background: inherit;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 2em;
}

.main-container {
  width: 80em;
  padding: 4em;
}

@media (max-width: 1024px) {
  .main-container {
    width: 100%;
    max-width: fit-content;
  }
}

@media (max-width: 768px) {
  .main-container {
    width: 100%;
    max-width: fit-content;
  }
}

.logo-text-img {
  width: 14em;
  width: 14em;
  /* border-color: white; */
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.1em;
  margin-bottom: 1em;
}

.main-title {
  font-weight: bold;
}
