.logo-icon-home {
  width: 200px;
  height: 70px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.logo-home {
  text-align: center;
  size: 60px;
  margin-top: 2em;
}

.center-logo-home {
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--main-text-color);
}

.heading-home {
  font-weight: bold;
}

.custom-home-heading {
  margin-top: 32px;
  font-family: Arial, Helvetica, sans-serif;
}
