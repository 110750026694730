.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: var(--formic-icon-background-color);
}
