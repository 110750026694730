.toggle-btns .MuiToggleButton-root {
    border-color: white;
    color: white;
    background-color: rgba(25, 118, 210, 0.08); 
    /* rgba(100,149,237, 0.54)*/
}

.toggle-btns .MuiToggleButton-root:hover {
    border-color: white;
    color: white;
    background-color: rgba(25, 118, 210, 0.3); 
    /* rgba(100,149,237, 0.54)*/
}

.toggle-btns .MuiToggleButton-root.Mui-selected {
    background-color: rgba(100,149,237, 0.8);
    color: white;
}

.toggle-btns .MuiToggleButton-root.Mui-selected:hover {
    background-color: rgba(100,149,237, 1);
    color: white;
}

.settings-container, .example-container {
    justify-content: center;
    color: white;
    min-width: 350px;
}

.example-container {
    flex-grow: 1;
}