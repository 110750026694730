.sub-header {
  text-align: center;
  color: var(--main-text-color);
  align-items: center;
}

.general-box {
  align-items: center;
  justify-content: center;
  color: white;
}

.general-box-default {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.para {
  margin-top: 1rem;
  margin-bottom: 0;
}

.redirect {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: rgb(231, 229, 229);
}

.profile-pic-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.profile-pic-link:hover {
  filter: brightness(1.2);
}

.profile-pic-link::before {
  content: "View Profile";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--main-text-color);
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.profile-pic-link:hover::before {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.admin {
  margin-top: 1em;
  color: #e0a966
}