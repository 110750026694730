.registration-form {
  background-color: var(--form-text-color);
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.registration-form input,
.registration-form select {
  margin-bottom: 10px;
}

.register-container {
  width: 540px;
  justify-content: center;
}

.error-messages-container {
  width: 700px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .container-ratio {
    width: 90%;
  }
  .register-container {
    width: 100%;
  }
  .error-messages-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .container-ratio {
    flex-direction: column;
  }
}

.content-height {
  height: 50vh !important;
}

.container-main {
  margin-top: 1rem !important;
  padding: 10px;
  height: 100vh;
  align-items: center;
}

.password-align {
  text-align: center;
  margin-bottom: 7px;
}

.forgot-pass {
  text-decoration: underline;
  color: var(--formic-text-color);
  text-align: center;
}

.container-ratio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-input-container {
  display: flex;
  align-items: center;
}

.password-input {
  flex: 1;
}

.bold-text {
  font-weight: bold;
}

@media (max-width: 1170px) {
  body {
    max-width: 1170px;
    margin: 0 auto;
  }
}

.width-setter {
  margin-bottom: 6px;
}

.box-style {
  border-radius: 8px;
}

.center-error {
  text-align: center !important;
  align-items: center;
  justify-content: center !important;
}

.main-comp {
  border-radius: 8px;
  padding: 20px 20px;
  background-color: #02121d;
  color: white;
}

.profile-password-label {
  display: flex;
    height: 100%;
}

.register-password {
  margin-top: 8px;
}