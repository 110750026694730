.sub-header {
  text-align: center;
  color: var(--main-text-color);
  align-items: center;
}

.login-container {
  width: 540px;
  justify-content: center;
}

.registration-form input,
.registration-form select {
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .container-ratio {
    width: 90%;
  }
  .login-container {
    width: auto;
  }
  .error-messages-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .container-ratio {
    flex-direction: column;
  }
}

.password-align {
  text-align: right;
  margin-bottom: 7px;
  margin-top: 5px;
}
.forgot-pass {
  text-decoration: underline;
  color: white;
  text-align: right;
}

.show-pass-align-custom {
  text-align: left;
}

.login-border {
  color: var(--form-text-color)
}

.text-align {
  text-align: center;
}

.google-icon {
  font-size: 20px;
  color: var(--main-text-color);
  transition: all 0.3s ease;
  justify-content: right;
}

.icon-class {
  text-align: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.icon-margin {
  text-align: right;
}