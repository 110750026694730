.pwd-button {
    background-color: transparent;
    border: none;
    cursor: pointer; 
    padding: 0;
  }
  
  .pwd-icon {
    color: #e0a966;
    font-size: 20px;
  }
  
  .pwd-popover {
    max-width: 300px;
  }