.sub-header {
  text-align: center;
  color: var(--main-text-color);
  align-items: center;
}

.redirect-container {
  width: 1000px;
}

.registration-form {
  background-color: var(--main-background-color);
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  max-width: 100%;
}

.registration-form input,
.registration-form select {
  margin-bottom: 10px;
}

.para-styling {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.redirect {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: rgb(231, 229, 229);
  width: 100%;
}

.redirect-style {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 12px;
}
