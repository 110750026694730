.profile-container {
  width: 1100px;
  justify-content: center;
  color: white
}

.unstaged-message {
  background-color: var(--unstaged-message);
  color: var(--main-text-color);
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.profile-label {
  display: flex;
  align-items: center;
  height: 100%;
}

.profile-style {
  align-items: right;
  height: 100%;
  text-align: center;
}

.profile-pic {
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.fixed-size-span {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 10px;
}

.topics {
  align-items: center;
  justify-content: center;
}

.checkbox-text {
  font-weight: bold;
}

.profile-style-toi {
  align-items: center;
  height: 100%;
  text-align: center;
}

.user-preferences {
  padding: 1em;
  border: solid;
  border-color: white;
}

.heading-style {
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
}

.toggle {
  color:#e0a966;
  font-size: 1.5em;
  border: transparent;
  border-radius: 0px;
  background: none;
}

.all-subjects-text {
  position: relative;
  top: -2px;
}

.all-subjects {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}

.margin-blank {
  margin: 0px 0 0 0;
}

.home {
  justify-content: left;
  position: absolute;
  font-size: 30px;
  color: var(--main-text-color);
  transition: all 0.3s ease;
}

.home:hover {
  font-size: 40px;
  color: var(--home-hover-color);
}

.header-style {
  margin-top: 20px;
  display: flex;
  margin-bottom: 20px;
}
