.logo {
  text-align: center;
  size: 50px;
  margin-bottom: 2em;
}

.image-logo {
  width: 120px;
  height: 120px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.sub-header {
  text-align: center;
  color: var(--main-text-color);
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}

.master-logo {
  margin-bottom: 1em;
}
