.navigation {
  background-color: var(--main-background-color);
}

.logo-icon {
  height: 2em;
  will-change: filter;
  transition: filter 300ms;
}

.nav-selection {
  cursor: pointer;
}

.navigation-logo {
  margin-left: 20px;
}