.updates-container {
  color: white !important;
  border: double;
  border-radius: 8px;
  border-color: white;
}

.links,
.links a {
  color: #e0a966 !important;
  font-weight: bold;
  text-decoration: none;
}

.img-style {
  height: 50em;
  width: 80em;
}

.img-button {
  height: 20em;
  width: 40em;
}

.image-button-container {
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.video-player {
  display: ruby-text;
}
