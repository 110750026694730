.footer-home {
  position: relative;
  width: 100vw;
  border-top: 1px solid var(--main-text-color);
  padding: 10px;
  bottom: 0;
  text-align: center;
  align-items: center;
}

.newsletter {
  color: var(--main-text-color);
}

.footer-btn {
  width: 50px;
}

.news {
  margin-top: 30px;
}
