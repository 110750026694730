svg.MuiCircularProgress-svg {
  height: 4em;
  width: 4em;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.MuiTypography-root {
  font-size: 2em;
  font-weight: bold;
  align-items: center;
}

.timer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 1em;
}

.circular-progress-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
}

.circular-progress-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(11%, -70%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular-progress-label-text {
  font-size: 2em;
  font-weight: bold;
  justify-content: center;
}
